import { createSlice } from "@reduxjs/toolkit";
import { ISetUser, ISetUserImage, ISetUserPermissions } from "./types"
import { Subscription } from "store/subscriptions/types";

const initialState = {
  id: null,
  email: null,
  name: null,
  image: null,
  createdAt: null,
  firstName: null,
  lastName: null,
  permissions: null,
  country: null,
  countryCode: null,
  isLoginAs: false,
  subscription: null,
  subscriptionStatus: null,
  isAACPActive: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: ISetUser) => {
      Object.assign(state, action.payload);
    },
    setUserData: (state, action) => {
      const payload: { [key: string]: any } = {};
      if (action.payload.firstName) {
        payload.firstName = action.payload.firstName;
      }
      if (action.payload.lastName) {
        payload.lastName = action.payload.lastName;
      }

      Object.assign(state, payload);
    },

    setUserImage: (state, action: ISetUserImage) => {
      state.image = action.payload;
    },

    setSubscription: (state, action: { payload: string, type: string }) => {
      state.subscriptionStatus = action.payload
    },

    setPermissions: (state, action: ISetUserPermissions) => {
      state.permissions = action.payload.permissions;
    },

    clearUser: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { actions } = userSlice;

export default userSlice.reducer;
